<template>
        <div class="top">
            <div class="left" >
                <img src="~/assets/img/room/menu.png" > 
                <v-menu activator="parent">
                    <v-list>
                        <v-list-item  v-for="(item, index) in newMenuList" :key="index" :value="index" @click="tap(item.index)">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class="logo">
                <img src="~/assets/img/room/title.png">
            </div>
            <div class="right" @click="$emit('clickedRight')">
                <img src="~/assets/img/room/call.png" @click="tapCallOther">
            </div>
        </div>
</template>
<script setup>

import { storeToRefs } from 'pinia'

const menuInject = inject("showMenu");
const callInject = inject("showCallOther");

const battle = useRoomStore();

const { battle_status } = storeToRefs(battle);

const isLoading = ref(true)

defineEmits(["clickedLeft", "clickedRight"])


function tapMenu(){
    menuInject()
}

function tapCallOther(){
    callInject();
}

const newMenuList = computed(()=>{
    if(battle_status.value !== BattleStatus.readying && battle_status.value !== BattleStatus.matching){
        return menulist.filter(item=>item.index !== 0);
    }

    if(battle_status.value !== BattleStatus.odaiing && battle_status.value !== battle_status.battling && battle_status.value !== BattleStatus.evaluating){
        return menulist.filter(item=>item.index !== 2);
    }

    return menulist;
})


function tap(index){
    if(index == 0){
        // useExitRoom().then(()=>{
        //     navigateTo('/')
        // })
        // battle.uuid = undefined;
        // battle.icon_id = undefined;
        // battle.nickname = "";
        isLoading.value = true
        useExitRoom().then((data)=>{
            if(data&&data.success == 1){
                battle.room = undefined;
                battle.enters = 0;
                battle.setResetAll();
                //退出
                navigateTo('/')
            }
            isLoading.value = false;
            
        }).catch((error)=>{
            isLoading.value = false;
        })
    }else if(index == 1){
        //使い方
    }else if(index == 2){
        //バトル強制終了
        isLoading.value = true
        useForceExit(battle).then((data)=>{
            if(data&&data.success == 1){
            }
            isLoading.value = false;
            
        }).catch((error)=>{
            isLoading.value = false;
        })
    }else if(index == 3){
        //問い合わせ
    }
}

</script>
<style lang="scss" scoped>
.top {
    text-align: center;
    position: sticky;
    width: 100%;
    top: 0px;
    padding:20px 10px 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background: $base-room-color;
    // background-color: yellow;
    height: 80px;

    >.left{
        flex: 0 0.4 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            width: 100%;
            max-width: 26px;
        }
    }
    >.logo{
        flex: 2 1 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            width: 100%;
            max-width: 158px;
        }
    }
    >.right{
        flex: 0 0.3 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            width: 100%;
            max-width: 58px;
        }
    }
}
</style>
<template>
    <v-dialog v-model="model">
        <template #="{ isActive }">
            <v-card class="dialog-card">
                <div class="top-bar">
                    <div class="close-btn" @click="close">
                        <span class="mdi mdi-close-circle-outline"></span>
                    </div>
                </div>
                <div class="title-style">友達を呼ぶ方法は2通り!</div>
                <div class="title-style qr-code-title">①QRコードで呼ぶ</div>
                <div class="qr-code-container">
                    <img class="next img" :src="imgaedataurl" >
                </div>
                <div class="icon-label">※↑カメラで読み取ってください</div>
                <div class="title-style step2">②URLをシェアして呼ぶ</div>
                <button class="url-container" @click="copyRoomInfo">
                    <div class="url">{{ url }}</div>   
                </button>
                <div class="icon-label">※↑タップでコピーできます。</div>
                <v-snackbar :timeout="1500" v-model="showInfo">
                {{ info }}
                </v-snackbar>
            </v-card>
        </template>
    </v-dialog>
</template>

<script lang="ts" setup>
import QRCode from 'qrcode';
const props = defineProps<{url:string,modelValue:boolean}>()
const emit = defineEmits(["closeDialog","update:modelValue"])

const imgaedataurl = ref("")

const model = computed({
    get:()=>{
        return props.modelValue
    },
    set:(val)=>{
        emit('update:modelValue', val)
    }
})



watch(()=> props.url,(newUrl) =>{
    if(newUrl){
        QRCode.toDataURL(newUrl, function (err: any, url: string) {
              if (err) {
                console.error('create qr code:', err);
                return;
              }
              imgaedataurl.value = url;
            })
    }
}, {immediate: true})
// const model = defineModel({required:false, default:false})
function close(){
    emit("closeDialog",)
}
const info = ref('');
const showInfo = ref(false);

function copyRoomInfo(){
  navigator.clipboard.writeText(props.url).then(() => {
    // success
    info.value = 'URLをコピーしました';
    showInfo.value = true;
  }, () => {
    // fail
    info.value = 'URLをコピー失敗しました';
    showInfo.value = true;
  });
}

</script>
<style lang="scss" scoped>
    .v-overlay {
        text-align: center;
        :deep(.v-overlay__content){
            margin: 0 !important;
        }
    }
    .dialog-card {
        width: 300px;
        height: 580px;
        margin: 0 auto; 
        border-radius: 25px !important;
        border-width: 3px;
        border-color: #000;

        @media (max-width: 375px) {
            width: 250px;
            height: 480px;
        }

        .top-bar {
            display: flex; 
            justify-content: center; 
            align-items: center; 
            justify-content: flex-end;
            span.mdi {
              font-size: 34px; /* 更改图标的大小 */
              margin-right: 5px;
            }
        }

        .title-style {
            text-align: center;
            color: #000;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .qr-code-title {
            margin-top: 20px;
        }

        .qr-code-container{
            display: flex;
            justify-content: center;
            &.img{
                width: 50%;
                max-width: 512px;
                height: auto;
                border: 2px solid #000;
                aspect-ratio: 1/1;
            }
        }
        
        .icon-label {
            text-align: center;
            color: #FF1717;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .step2 {
            margin-top: 20px;
        }

        .url-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 25px;
            border-width: 2px;
            border-style: solid;
            border-color: #000;
            padding: 8px 0px;

            .url{
                font-size: 0.6em;
                text-align: center;
                width: 206px;
                white-space: nowrap;
                text-overflow: ellipsis; 
                overflow: hidden;
            }
        }

    }
</style>
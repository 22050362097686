<template>
    <v-dialog v-model="model">
        <template #="{ isActive }">
            <v-card class="dialog-card">
                <div class="top-bar">
                    <div class="text-center title-container">プロフ変更</div>
                    <img src="/img/profileCloseButton.svg" @click="close">
                </div>
                <div class="img-container">
                    <v-img
                        :src="mapToIconWith(icon)"
                        class="profile-icon"
                    ></v-img>
                </div>
                <input 
                    class="text-center name-container" 
                    :value="name"
                    @input="userNameDidEdit"
                    maxlength="12">
                <div class="icon-label">アイコンを変更する</div>
                <div class="icons-containers">
                <div class="icon-container"  v-for="n in 19" :key="n" >
                    <img :src="mapToIconWith(n)" @click="didSelectedItem(n)">
                </div>
                </div>
            </v-card>
        </template>
    </v-dialog>
</template>

<script lang="ts" setup>
const props = defineProps<{context:string,icon:number,userName:string,modelValue:boolean}>()
const emit = defineEmits(["closeDialog", "didSelectedItem", "update:userName",'update:modelValue'])

const didEdit = ref(false)
const model = computed({
    get:()=>{
        if(!props.modelValue){
            didEdit.value = false
        }
        return props.modelValue
    },
    set:(val)=>{
        if(!val){
            didEdit.value = false
        }
        emit('update:modelValue', val)
    }
})

const name = computed({
    get:() => {
        if(props.userName.length > 0){
            return props.userName
        }
        if(didEdit.value){
            return ""
        }
        return characters[`${props.icon}`]
    }
})


function close(){
    emit("closeDialog",)
}

function userNameDidEdit(event:Event){
    didEdit.value = true
    const target = event.target as HTMLInputElement;
    emit('update:userName', target.value)
}

function didSelectedItem(index:number){
    emit("didSelectedItem", index)
}

</script>
<style lang="scss" scoped>
    .v-overlay {
        text-align: center;
        :deep(.v-overlay__content){
            margin: 0 !important;
        }
    }
    .dialog-card {
        width: 300px;
        height: 580px;
        margin: 0 auto; 
        border-radius: 25px !important;
        border-width: 3px;
        border-color: #000;

        @media (max-width: 375px) {
            width: 250px;
            height: 480px;
        }

        .top-bar {
            display: flex; 
            justify-content: center; 
            align-items: center; 
            .title-container {
                flex-grow: 1; 
                text-align: center; 
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 13px;
                margin-bottom: 52px;

                @media (max-width: 375px) {
                    margin-bottom: 15px;
                }
            }

            img {
                position: absolute;
                right: 5px;
                top: -5px;
            }
        }


        .img-container {
            margin-bottom: 9px;
            .profile-icon {
                margin: 0 auto; 
                border-radius: 50%;  
                border: 3px solid #000;
                width: 90px;  
                height: 90px; 
                object-fit: cover; 
            }
        }
        
        .name-container {
            margin-top: 10px;
            border-style: solid;
            border-color: #000;
            border-width: 2px;
            margin: 0 auto;
            padding: 2px 21px;
            color: #000;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 2.2px;
            width: 220px;
            &:focus {
                border-color: gray;
                outline: none;
            }
        }
        
        .icon-label {
            margin-top: 24px;
            margin-left: 14.5px;
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media (max-width: 375px) {
                margin-top: 10px;
            }
        }

        .icons-containers {
            display: flex;
            flex-wrap: wrap;
            border-radius: 30px;
            background-color: #D9D9D9;
            padding: 10px 12px;
            margin: 10px 16px;
            .icon-container {
                flex: 0 0 20%;
                width: 15%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
</style>
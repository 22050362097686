<template>
  <div class="blank">
    <PageProfileCutomerRoom
      v-model="isShowQrCode"
      :url="roomUrl"
      @closeDialog="hideQrCode">
    </PageProfileCutomerRoom>

    <PageProfileCustomer
            v-model="isShowEditUserProfile" 
            v-model:userName="nickname"
            :icon="icon_id"
            @closeDialog="hideEditUserProfile" 
            @didSelectedItem="didSelectedItem">
    </PageProfileCustomer>

    <div class="app-main">
        <CommonRoomHeader class="common-title" @clickedRight="showQrCode"></CommonRoomHeader>
    <slot/>
    <v-overlay  :model-value="showInit" width="100%" height="100%" persistent>
        <v-progress-circular
        :size="50"
        color="red"
        indeterminate
        class="loading"
        fullscreen
        >
        <span class="loadtext">初期化</span>
        </v-progress-circular>
    </v-overlay>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from 'pinia'
const showInit = ref(false);
const route = useRoute()
const battle = useRoomStore();
const useDialogstore = useDialogStore();
const {isShowQrCode, isShowEditUserProfile, roomUrl} = storeToRefs(useDialogstore)
const {icon_id, nickname} = storeToRefs(battle)
const {showQrCode, hideQrCode, showEditUserProfile, hideEditUserProfile} = useDialogstore

watch(isShowEditUserProfile, (newValue, oldValue) => {
  if (!newValue) {
    useUpdateRoomUser(nickname.value,icon_id.value)
  }
});

onMounted(() => {
  showInit.value = true;
  useRecovery(battle,(loaded,data)=>{
          if(data&&data.in_battle){
            console.log(route.name);
            if(route.name == 'room'){
              navigateTo('/room/battle')
            }
          }
          showInit.value = false;
});
})

function didSelectedItem(index){
  icon_id.value = index
}

provide("showCallOther", showCallOther);
function showCallOther() {
  // showCallOther
  console.log("showCallOther");
}
provide("showMenu", showMenu);
function showMenu() {
  // showCallMenu
  console.log("showMenu");
}
</script>
<style lang="scss" scoped>
.blank{
  background-color: $base-room-color;
}
.app-main{
    height: 100%;
    width: 100%;
    max-width: 500px;
    min-height: 100dvh;
    margin: 0 auto;
    background-repeat: repeat;
    background-color: $base-room-color;
    background-blend-mode:multiply;
    padding: max(env(safe-area-inset-top, 20px), 0px) max(env(safe-area-inset-right, 20px), 20px) max(env(safe-area-inset-top, 20px), 0px) max(env(safe-area-inset-left, 20px), 20px);
    
    >.common-title{
        z-index:1;
    }
}
.loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
.loadtext{
  color: red;
  font-size: 10px;
  font-weight: bold;
}
</style>